<section class="error-section">
  <div class="error-wrapper">
    <h1 class="error-title">403</h1>

    <div class="error-info">
      <p class="error-subtitle">{{'forbidden.forbiddenLabel' | translate}}</p>

      <p class="error-text">{{'forbidden.forbiddenInfoLabel' | translate}}</p>

      <button (click)="backButtonClick()" class="big-button button-primary wide-button"
              mat-button>{{'general.goBackLabel' | translate}}</button>
    </div>
  </div>
</section>
