<div (click)="$event.stopPropagation()" [formGroup]="visitFiltersFormGroup" class="filter-menu">
  <h2 class="filter-title">{{'filterPatients.filterVisitsLabel' | translate}}</h2>

  <h3 class="filter-label">{{'filterPatients.byDoctorLabel' | translate}}</h3>

  <mat-radio-group aria-label="Select an option" formControlName="byDoctor">
    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('byDoctor').value === filterVisitItem.None"
                      [value]="filterVisitItem.None">
      {{'filterPatients.noneLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('byDoctor').value === filterVisitItem.MyVisits"
                      [value]="filterVisitItem.MyVisits">
      {{'filterPatients.myVisitsLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('byDoctor').value === filterVisitItem.Others"
                      [value]="filterVisitItem.Others">
      {{'filterPatients.othersLabel' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <h3 class="filter-label">{{'filterPatients.visitTimeLabel' | translate}}</h3>

  <mat-radio-group aria-label="Select an option"
                   formControlName="visitTime">
    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('visitTime').value == filterItem.None"
                      [value]="filterItem.None">
      {{'filterPatients.noneLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('visitTime').value == filterItem.Ascending"
                      [value]="filterItem.Ascending">
      {{'filterPatients.ascendingLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="visitFiltersFormGroup.get('visitTime').value == filterItem.Descending"
                      [value]="filterItem.Descending">
      {{'filterPatients.descendingLabel' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <h3 *ngIf="false" class="filter-label">{{'profileSettings.languageLabel' | translate}}</h3>

  <div *ngIf="false" class="input-container">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'filterPatients.chooseLanguageLabel' | translate}}</mat-label>

      <mat-select>
        <mat-option [value]="1">value 1</mat-option>

        <mat-option [value]="2">value 2</mat-option>

        <mat-option [value]="3">value 3</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button (click)="applyFilters()" class="filter-button button-primary wide-button" mat-button>
    {{'filterPatients.applyFiltersLabel' | translate}}
  </button>
</div>
