<div *ngFor="let groupName of questionAnswers">
  <div *ngIf="groupName.selectedOptions.length || groupName.textAnswer">
    <h3 class="patient-gray-subtitle">{{groupName?.groupName}}</h3>

    <div class="patient-personal-medical-history-wrapper">
      <div *ngFor="let selectedOption of groupName.selectedOptions">
        <div *ngIf="groupName?.selectedOptions"
             class="patient-personal-medical-history-block">{{selectedOption}}</div>
      </div>
    </div>
  </div>

  <p *ngIf="groupName.textAnswer" class="patient-description">{{groupName?.textAnswer}}</p>
</div>
