<div [class.offline]="isOffline" [formGroup]="prescriptionFormGroup" class="modal-wrapper">
  <h1 *ngIf="!isOffline" class="page-title">
    {{data?.isEdit ? ('patientVisit.editRecommendationsLabel' | translate) : ('patientVisit.addNewRecommendationsLabel' | translate)}}
  </h1>

  <div class="input-medical-name-container">
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item">
        <mat-label>{{'patientVisit.medicationTradeNameLabel' | translate}}</mat-label>

        <input [required]="!prescriptionFormGroup?.controls?.genericName.value?.trim()" formControlName="tradeName"
               matInput/>
      </mat-form-field>
    </div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item">
        <mat-label>{{'patientVisit.medicationGenericNameLabel' | translate}}</mat-label>

        <input [required]="!prescriptionFormGroup?.controls?.tradeName.value?.trim()"
               formControlName="genericName"
               matInput/>
      </mat-form-field>
    </div>
  </div>

  <div class="input-container">
    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.strengthLabel' | translate}}</mat-label>

      <input formControlName="strength" matInput min="0" type="number"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.doseLabel' | translate}}</mat-label>

      <mat-select formControlName="strengthType">
        <mat-option *ngFor="let strengthType of strengthType" [value]="strengthType?.code">
          {{strengthType?.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.quantityLabel' | translate}}</mat-label>

      <input formControlName="usageCount" matInput min="0" type="number"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.formLabel' | translate}}</mat-label>

      <mat-select formControlName="usageForm">
        <mat-option *ngFor="let usageForm of usageForm" [value]="usageForm?.code">
          {{usageForm?.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.routeLabel' | translate}}</mat-label>

      <mat-select formControlName="usageRoute">
        <mat-option *ngFor="let usageRoute of usageRoute" [value]="usageRoute?.code">
          {{usageRoute?.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.timeLabel' | translate}}</mat-label>

      <input formControlName="usageReps" matInput min="0" type="number"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.frequencyLabel' | translate}}</mat-label>

      <mat-select formControlName="usageFrequency">
        <mat-option *ngFor="let usageFrequency of usageFrequency" [value]="usageFrequency?.code">
          {{usageFrequency?.name | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="input-container-item">
      <mat-label>{{'patientVisit.duringLabel' | translate}}</mat-label>

      <input formControlName="during" matInput min="0" type="number"/>
    </mat-form-field>
  </div>

  <div class="input-description-container">
    <div class="input-wrapper">
      <mat-form-field appearance="outline" class="textarea">
        <mat-label>{{'general.descriptionLabel' | translate}}</mat-label>

        <textarea formControlName="description" matInput></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="button-container">
    <button (click)="onCloseForm()" class="transparent-button" mat-button>{{'general.cancelLabel' | translate}}</button>

    <button (click)="goBack()" *ngIf="isOffline" class="transparent-button" mat-button>
      {{'general.goBackLabel' | translate}}
    </button>

    <button (click)="saveForm(true)" *ngIf="isOffline" class="button-primary" mat-button>
      {{'patientVisit.skipRecommendationsLabel' | translate}}
    </button>

    <button (click)="saveForm(false)" [disabled]="prescriptionFormGroup?.invalid" class="button-primary" mat-button>
      {{'general.saveLabel' | translate}}
    </button>
  </div>
</div>
