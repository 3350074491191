import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'formatFileSize',
  pure: true
})

export class FileSizeConverterPipe implements PipeTransform {
  public transform(bytes: number): string {
    if (bytes) {
      if (bytes < 1000) {
        return bytes + ' B';
      } else if (bytes < 1000 * 1000) {
        return Number((bytes / 1000).toFixed(2)) + ' KB';
      } else {
        return Number((bytes / (1000 * 1000)).toFixed(2)) + ' MB';
      }
    } else {
      return '';
    }
  }
}
