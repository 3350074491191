import { TimeModeEnum } from '../enums';

export const timeOptionsListConstant = [
  {
    'code': TimeModeEnum.TwentyFourHours,
    'name': 'profileSettings.twentyFourHoursHoursLabel',
  },
  {
    'code': TimeModeEnum.TwelveHours,
    'name': 'profileSettings.twelveHoursLabel',
  }
];
