export enum AppointmentStatusEnum {
  Draft = 0,
  DoctorDecisionPending = 1,
  ResponsePending = 2,
  PatientDecisionPending = 3,
  Scheduled = 4,
  Ongoing = 5,
  Declined = 6,
  VisitFinished = 7,
  FollowUpDecisionPending = 8,
  FollowUpScheduled = 9,
  FollowUpDeclined = 10,
  FollowUpFinished = 11,
  Canceled = 12,
  Expired = 13
}
