import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessagesComponent } from '../../../+messages/messages.component';

import { familyMemberRelationshipListConstant } from '../../../core/constants';

import { NewFamilyMemberFormGroup } from '../../../core/types';


@Component({
  templateUrl: './add-family-member-dialog.component.html',
  styleUrls: ['./add-family-member-dialog.component.scss']
})
export class AddFamilyMemberDialogComponent implements OnInit {
  public familyMemberRelationshipList = familyMemberRelationshipListConstant;
  public familyMemberFormGroup: FormGroup<NewFamilyMemberFormGroup>;

  constructor(private readonly dialogRef: MatDialogRef<MessagesComponent>,
              private readonly fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.setForm();
  }

  public add(): void {
    this.dialogRef.close(this.familyMemberFormGroup.value);
  }

  private setForm(): void {
    this.familyMemberFormGroup = this.fb.group<NewFamilyMemberFormGroup>({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      relationship: this.fb.control(null, [Validators.required])
    });
  }
}
