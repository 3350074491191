<div class="modal-wrapper">
  <h1 class="page-title">{{'messages.writeMessageLabel' | translate}}</h1>

  <div class="input-description-container">
    <div class="input-wrapper">
      <mat-form-field appearance="outline" class="textarea">
        <mat-label>{{'messages.textLabel' | translate}}</mat-label>

        <textarea [formControl]="messageTextFormControl" matInput></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="button-container">
    <button [mat-dialog-close]="false" class="transparent-button"
            mat-button>{{'general.cancelLabel' | translate}}</button>

    <button (click)="sendMessage()" [disabled]="!isTextValue" class="button-primary" mat-button>
      {{'messages.sendLabel' | translate}}
    </button>
  </div>
</div>
