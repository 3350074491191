<div [formGroup]="recommendationFormGroup" class="modal-wrapper">
  <h1 *ngIf="!isOffline" class="page-title">
    {{(data?.isEdit ? 'patientVisit.editRecordLabel' : 'patientVisit.addRecordLabel') | translate}}
  </h1>

  <div [ngStyle]="{width: isOffline ? '100%' : '50rem '}" class="input-wrapper">
    <mat-form-field appearance="outline" class="textarea">
      <mat-label>{{'patientVisit.diagnoseLabel' | translate}}</mat-label>

      <textarea formControlName="diagnose" matInput rows="5"></textarea>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="recommendationFormGroup.controls.diagnose">
    </vi-clinic-control-validation-message>

    <mat-form-field appearance="outline" class="textarea">
      <mat-label>{{'patientVisit.furtherInvestigationLabel' | translate}}</mat-label>

      <textarea formControlName="furtherInvestigation" matInput rows="5"></textarea>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="recommendationFormGroup.controls.furtherInvestigation">
    </vi-clinic-control-validation-message>

    <mat-form-field appearance="outline" class="textarea">
      <mat-label>{{'patientVisit.medicalNoteLabel' | translate}}</mat-label>

      <textarea formControlName="note" matInput rows="5"></textarea>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="recommendationFormGroup.controls.note">
    </vi-clinic-control-validation-message>


    <mat-form-field appearance="outline" class="textarea">
      <mat-label>{{'patientVisit.testResultsLabel' | translate}}</mat-label>

      <textarea formControlName="testResults" matInput rows="5"></textarea>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="recommendationFormGroup.controls.testResults">
    </vi-clinic-control-validation-message>
  </div>

  <div class="button-container">
    <button (click)="onCloseForm()" class="transparent-button" mat-button>{{'general.cancelLabel' | translate}}</button>

    <button (click)="saveForm()" [disabled]="!recommendationFormGroup.valid" class="button-primary" mat-button>
      {{isOffline ? ('patientEHR.nextStepLabel' | translate) : ('general.saveLabel' | translate)}}
    </button>
  </div>
</div>
