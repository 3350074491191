<div [formGroup]="generalInfo" class="modal-wrapper">

  <div class="input-container-item w-100">
    <mat-form-field appearance="outline" class="input-container-item-datepicker">
      <mat-label>{{'profileSettings.birthdayLabel' | translate}}</mat-label>

      <input [matDatepicker]="dateOfBirth"
             [placeholder]="'general.datePlaceholderLabel' | translate"
             [max]="maxBirthDate"
             [min]="minBirthDate"
             formControlName="dateOfBirth"
             (click)="dateOfBirth.open()"
             matInput/>

      <vi-clinic-control-validation-message [control]="generalInfo?.controls.dateOfBirth">
      </vi-clinic-control-validation-message>
    </mat-form-field>

    <mat-datepicker-toggle [for]="dateOfBirth" matSuffix></mat-datepicker-toggle>

    <mat-datepicker #dateOfBirth></mat-datepicker>
  </div>

  <div class="inputs-full-name">
    <div class="input-first-name">
      <mat-form-field appearance="outline" class="input-container-item">
        <mat-label>{{'patientEHR.heightLabel' | translate}}</mat-label>

        <input formControlName="height" matInput type="number"/>
      </mat-form-field>

      <vi-clinic-control-validation-message [control]="generalInfo?.controls.height">
      </vi-clinic-control-validation-message>
    </div>

    <div class="input-last-name">
      <mat-form-field appearance="outline" class="input-container-item">

        <mat-select [value]="heightUnits[1].value" formControlName="heightUnit">
          <mat-option *ngFor="let height of heightUnits" [value]="height?.value">
            {{height?.displayedName | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <vi-clinic-control-validation-message [control]="generalInfo?.controls.heightUnit">
      </vi-clinic-control-validation-message>
    </div>
  </div>

  <div class="inputs-full-name">
    <div class="input-first-name">
      <mat-form-field appearance="outline" class="input-container-item">
        <mat-label>{{'patientEHR.weightLabel' | translate}}</mat-label>

        <input formControlName="weight" matInput type="number"/>
      </mat-form-field>

      <vi-clinic-control-validation-message [control]="generalInfo?.controls.weight">
      </vi-clinic-control-validation-message>
    </div>

    <div class="input-last-name">
      <mat-form-field appearance="outline" class="input-container-item">

        <mat-select [value]="weightUnits[1].value" formControlName="weightUnit">
          <mat-option *ngFor="let weight of weightUnits" [value]="weight?.value">
            {{weight?.displayedName | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <vi-clinic-control-validation-message [control]="generalInfo?.controls.weightUnit">
      </vi-clinic-control-validation-message>
    </div>
  </div>

  <div class="input-container radio-group">
    <mat-radio-group formControlName="gender">
      <mat-radio-button disableRipple [value]="gender.Male">{{'general.maleLabel' | translate}}</mat-radio-button>
      <mat-radio-button disableRipple [value]="gender.Female">{{'general.femaleLabel' | translate}}</mat-radio-button>
    </mat-radio-group>

    <vi-clinic-control-validation-message [control]="generalInfo?.controls?.gender">
    </vi-clinic-control-validation-message>
  </div>

  <div class="button-container">
    <button (click)="onNoClick()" class="transparent-button" mat-button>
      {{'general.cancelLabel' | translate}}
    </button>

    <button (click)="addPatient()" [disabled]="!generalInfo.valid" class="button-primary" mat-button>
      {{'patientEHR.nextStepLabel' | translate}}
    </button>
  </div>
</div>

