import { Component, Input } from '@angular/core';

import { QuestionAnswerInterface } from '../../../core/interfaces';


@Component({
  selector: 'vi-clinic-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss']
})
export class MedicalHistoryComponent {
  @Input() public questionAnswers: QuestionAnswerInterface[];
}
