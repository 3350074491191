export enum UsageRouteEnum {
  Oral = 0,
  IV = 1,
  IM = 2,
  SC = 3,
  Rect = 4,
  Vagn = 5,
  Eye = 6,
  Nasal = 7,
  Ear = 8,
  Top = 9,
  Other = 10
}
