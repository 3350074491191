<div class="modal-wrapper">
  <h1
    class="page-title">{{'medicalInformation.completeMedicalHistoryLabel' | translate}}
  </h1>

  <vi-clinic-offline-ehr-general-information (noClick)="closeDialog()"
                                             [patientId]="data?.patientId"
                                             [familyMemberId]="data?.familyMemberId"
                                             (patient)="addPatient($event)"></vi-clinic-offline-ehr-general-information>
</div>
