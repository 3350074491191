<div class="starts-rating-container" (mouseleave)="resetHover()">
  <div class="black-stars d-flex stars {{size}}" [ngClass]="{'selectable' : selectable}"
       #blankStars>
    <mat-icon (click)="setNewRating(1)" (mouseenter)="onStarHover(1)" [svgIcon]="'star-icon-gray'"></mat-icon>
    <mat-icon (click)="setNewRating(2)" (mouseenter)="onStarHover(2)" [svgIcon]="'star-icon-gray'"></mat-icon>
    <mat-icon (click)="setNewRating(3)" (mouseenter)="onStarHover(3)" [svgIcon]="'star-icon-gray'"></mat-icon>
    <mat-icon (click)="setNewRating(4)" (mouseenter)="onStarHover(4)" [svgIcon]="'star-icon-gray'"></mat-icon>
    <mat-icon (click)="setNewRating(5)" (mouseenter)="onStarHover(5)" [svgIcon]="'star-icon-gray'"></mat-icon>
  </div>

  <div id="filled-stars" class="stars {{size}}" #filledStars [ngClass]="{'selectable' : selectable}">
    <mat-icon (click)="setNewRating(1)" (mouseenter)="onStarHover(1)" [svgIcon]="'star-icon-gold'"></mat-icon>
    <mat-icon (click)="setNewRating(2)" (mouseenter)="onStarHover(2)" [svgIcon]="'star-icon-gold'"></mat-icon>
    <mat-icon (click)="setNewRating(3)" (mouseenter)="onStarHover(3)" [svgIcon]="'star-icon-gold'"></mat-icon>
    <mat-icon (click)="setNewRating(4)" (mouseenter)="onStarHover(4)" [svgIcon]="'star-icon-gold'"></mat-icon>
    <mat-icon (click)="setNewRating(5)" (mouseenter)="onStarHover(5)" [svgIcon]="'star-icon-gold'"></mat-icon>
  </div>
</div>
