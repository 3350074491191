<div class="calendar-wrapper">
  <div class="calendar-paginator-wrapper">
    <p class="date">{{ viewDate | calendarDate:(myView + 'ViewTitle'): localLanguage: 1 }}</p>
    <div class="small-button">
      <button (viewDateChange)="startOfWeek($event)"
              [(viewDate)]="viewDate"
              [view]="myView"
              mat-button
              mwlCalendarPreviousView>
        <img alt="" src="../../../../assets/icons/general/arrow-calendar-paginator-left-icon.svg">
      </button>
      <button (viewDateChange)="startOfWeek($event)"
              [(viewDate)]="viewDate"
              [view]="myView"
              mat-button
              mwlCalendarNextView>
        <img alt="" src="../../../../assets/icons/general/arrow-calendar-paginator-right-icon.svg">
      </button>
    </div>
  </div>

  <div class="overflow-auto h-100">
    <mwl-calendar-week-view
      (eventClicked)="onClickEvent($event.event.id)"
      [dayEndHour]="24"
      [dayStartHour]="7"
      [events]="events"
      [hourSegmentHeight]="40"
      [hourSegments]="1"
      [locale]="localLanguage"
      [viewDate]="viewDate"
      [weekStartsOn]="1">
    </mwl-calendar-week-view>
  </div>
</div>
