<h1 [innerHTML]="data.title | translate" class="page-title"></h1>

<div class="button-container">
  <button (click)="closeDialog()" class="transparent-button" mat-button>
    {{'general.cancelLabel' | translate}}
  </button>

  <button (click)="closeAll()" class="button-primary" mat-button>
    {{'notification.closeLabel' | translate}}
  </button>
</div>
