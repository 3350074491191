<div class="modal-wrapper">
  <h1 class="page-title">{{'messages.editChatNameLabel' | translate}}</h1>

  <div class="filters">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <input [formControl]="newChatName" [placeholder]="'general.searchLabel' | translate" matInput/>
    </mat-form-field>
  </div>

  <div class="button-container">
    <button class="transparent-button" mat-button mat-dialog-close>{{'general.cancelLabel' | translate}}</button>

    <button (click)="renameChat()" class="button-primary" mat-button>{{'messages.updateLabel' | translate}}</button>
  </div>
</div>
