<div class="modal-wrapper">
  <p class="warning-text">{{'messages.youAreSureWantRemoveChatLabel' | translate}}</p>

  <div class="button-container">
    <button [mat-dialog-close]="false" class="transparent-button" mat-button>
      {{'general.cancelLabel' | translate}}
    </button>

    <button [mat-dialog-close]="true" class="button-primary" mat-button>{{'messages.confirmLabel' | translate}}</button>
  </div>
</div>
