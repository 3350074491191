<div *ngIf="visitInfo" class="record-block">
  <h4 class="record-block-title">{{'patientVisit.diagnoseLabel' | translate}}</h4>

  <p class="record-block-description">{{visitInfo?.diagnose}}</p>
</div>

<div *ngIf="visitInfo" class="record-block">
  <h4 class="record-block-title">{{'patientVisit.furtherInvestigationLabel' | translate}}</h4>

  <p class="record-block-description">{{visitInfo.furtherInvestigation}}</p>
</div>

<div *ngIf="visitInfo && role === roleEnum.Doctor" class="record-block">
  <h4 class="record-block-title">{{'patientVisit.medicalNoteLabel' | translate}}</h4>

  <p class="record-block-description">{{visitInfo.note}}</p>
</div>

<div *ngIf="visitInfo?.testResults" class="record-block">
  <h4 class="record-block-title">{{'patientVisit.testResultsLabel' | translate}}</h4>

  <p class="record-block-description">{{visitInfo.testResults}}</p>
</div>
