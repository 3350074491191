export interface ColleaguesInterface {
  firstName: string;
  lastName: string;
  specialtyId: number;
  photoUrl: number;
  id: string;
  doctorId?: string;
  specialtyName: string | number;
  fullName?: string;
  countryName?: string;
}

export class Colleague {
  public firstName: string;
  public lastName: string;
  public photoUrl: number;
  public id: string;

  constructor(doctor: ColleaguesInterface) {
    this.firstName = doctor.firstName;
    this.lastName = doctor.lastName;
    this.photoUrl = doctor.photoUrl;
    this.id = doctor.doctorId;
  }
}
