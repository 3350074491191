import { FamilyMemberEnum } from '../enums';

export const familyMemberRelationshipListConstant = [
  {
    'code': FamilyMemberEnum.Other,
    'name': 'medicalInformation.otherLabel',
  },
  {
    'code': FamilyMemberEnum.Wife,
    'name': 'medicalInformation.wifeLabel',
  },
  {
    'code': FamilyMemberEnum.Husband,
    'name': 'medicalInformation.husbandLabel',
  },
  {
    'code': FamilyMemberEnum.Sister,
    'name': 'medicalInformation.sisterLabel',
  },
  {
    'code': FamilyMemberEnum.Brother,
    'name': 'medicalInformation.brotherLabel',
  },
  {
    'code': FamilyMemberEnum.Daughter,
    'name': 'medicalInformation.daughterLabel',
  },
  {
    'code': FamilyMemberEnum.Son,
    'name': 'medicalInformation.sonLabel',
  },
  {
    'code': FamilyMemberEnum.Mother,
    'name': 'medicalInformation.motherLabel',
  },
  {
    'code': FamilyMemberEnum.Father,
    'name': 'medicalInformation.fatherLabel',
  },
  {
    'code': FamilyMemberEnum.GrandMother,
    'name': 'medicalInformation.grandMotherLabel',
  },
  {
    'code': FamilyMemberEnum.GrandFather,
    'name': 'medicalInformation.grandFatherLabel',
  }
];
