<ng-container *ngIf="!data?.readonly; else readonlyMode">
  <div class="medical-history-wrapper">
    <h1 class="page-title">
      {{ 'patients.patientsEHRLabel' | translate }} {{ 'patientEHR.personalMedicalHistoryLabel' | translate | lowercase }}
    </h1>

    <div *ngFor="let record of ehr?.questions" class="scope">
      <h2>{{ record?.groupName }}</h2>

      <h1>{{ record?.title }}</h1>

      <p>{{ record?.hint }}</p>

      <div class="checkboxes-container" *ngIf="record?.questionType === 0">
        <div class="checkbox-container" *ngFor="let form of  record.options; let index = index">
          <mat-checkbox [matTooltip]="form?.value" matTooltipClass="tooltip" (change)="changeEvent($event)"
                        [aria-label]="record?.id" [aria-labelledby]="form?.value"
                        [checked]="record?.answer?.selectedOptions.includes(form?.id)"
                        [value]="form.id" class="checkbox">
            {{ form?.value }}
          </mat-checkbox>
        </div>
      </div>

      <textarea (change)="change($event, record?.id)" *ngIf="record?.questionType === 1" [aria-describedby]="record?.id"
                [value]="record?.answer?.value"
                class="text-area" matInput type="text">
    </textarea>
    </div>
  </div>

  <div class="button-container">
    <button (click)="onNoClick(false)" class="transparent-button" mat-button>
      {{ 'general.cancelLabel' | translate }}
    </button>

    <button (click)="saveData()" [disabled]="!changed" class="button-primary" mat-button>
      {{ 'general.saveLabel' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #readonlyMode>
  <div class="medical-history-wrapper">
    <h1 class="page-title">
      {{ 'patientEHR.personalMedicalHistoryLabel' | translate }}
    </h1>

    <vi-clinic-medical-history [questionAnswers]="questionAnswers"></vi-clinic-medical-history>
  </div>

  <div class="button-container">
    <button (click)="onNoClick(false)" class="transparent-button" mat-button>
      {{ 'general.closeLabel' | translate }}
    </button>
  </div>
</ng-template>
