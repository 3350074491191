import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AcceptFollowUpVisitInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FollowUpApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/FollowUp`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public postScheduleVisit(id: string, data: { options: Date[] }): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}/schedule/${id}`, data);
  }

  public followUpDecisionRequest(body: AcceptFollowUpVisitInterface): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/makedecision/${body.appointmentId}`, body);
  }
}
