export enum FamilyMemberEnum {
  Other = 0,
  Wife = 1,
  Husband = 2,
  Sister = 3,
  Brother = 4,
  Daughter = 5,
  Son = 6,
  Mother = 7,
  Father = 8,
  GrandMother = 9,
  GrandFather = 10
}
