import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'vi-clinic-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {
  @Input() public noRecords = true;
  @Input() public isLoading = false;
  @Input() public showMoreButton = false;
  @Input() public loadDataByClick = false;

  @Output() public loadDataEmitter = new EventEmitter<void>();

  private reachedListBottom$: Subject<void> = new Subject();
  private unsubscribe$: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    this.subscribeScrollBottom();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onListScroll(event): void {
    if (this.loadDataByClick) {
      return;
    }

    const offsetBottom = event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop;

    if (offsetBottom > 200) {
      return;
    }

    this.reachedListBottom$.next();
  }

  private subscribeScrollBottom(): void {
    this.reachedListBottom$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
          this.loadDataEmitter.emit();
        }
      );
  }

  public loadData(): void {
    if (!this.loadDataByClick) {
      return;
    }
    this.loadDataEmitter.emit();
  }
}
