import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';

import { countryCodeConstant } from '../../../core/constants';
import { PaymentProviderTypeEnum, RoleEnum } from '../../../core/enums';

import {
  Patient,
  PatientInterface,
  RequestAppointmentInterface,
  UserGeneralInfoInterface
} from '../../../core/interfaces';

import {
  AuthService,
  PatientService,
  RequestDetailsService,
  SelectedAppointmentService,
  VideoService
} from '../../../core/services';


@Component({
  selector: 'vi-clinic-appointments-list-item',
  templateUrl: './appointments-list-item.component.html',
  styleUrls: ['./appointments-list-item.component.scss']
})
export class AppointmentsListItemComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public appointmentInfo: RequestAppointmentInterface;
  @Input() public isAppointment: boolean;

  @Output() public joinToWaitingRoomStatus = new EventEmitter<boolean>();
  @Output() public patientId = new EventEmitter<string>();
  @Output() public selectNewCard = new EventEmitter();
  public role: RoleEnum;
  public appointmentStatus: boolean = false;
  public isItemSelected: boolean;
  public countryCode = countryCodeConstant;
  public currentPatientInfo: UserGeneralInfoInterface;
  public currentDoctorInfo: UserGeneralInfoInterface;
  public isAvailableWaitingRoom: boolean = false;
  public isFree: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly requestDetailsService: RequestDetailsService,
              private readonly patientService: PatientService,
              private readonly selectedAppointmentService: SelectedAppointmentService,
              private readonly videoService: VideoService,
              private readonly router: Router,
              private readonly authService: AuthService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.appointmentInfo) {
      this.setUser();
      this.isFree = this.appointmentInfo?.payment === null ||
        this.appointmentInfo.payment?.providerType === PaymentProviderTypeEnum.Free ||
        this.appointmentInfo.isFree;
    }
  }

  public ngOnInit(): void {
    this.authService.currentUser$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        if (user.role) {
          this.role = user.role;
          this.setUser();
        }
      });

    if (this.isAppointment) {
      this.subscribeToSelectedPatient();
    } else {
      this.subscribeToSelectedRequest();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectItem(request?: RequestAppointmentInterface): void {
    if (!this.isItemSelected) {
      if (this.isAppointment) {
        this.setAppointmentInfo();
      } else {
        this.requestDetailsService.setSelectedRequest(request);
      }
    }

    this.selectNewCard.emit()
  }

  public checkAppointmentStatus(value: boolean): void {
    setTimeout(() => this.appointmentStatus = value, 0);
    if (value && !this.isAvailableWaitingRoom) {
      this.isAvailableWaitingRoom = true;
    }
  }

  public joinToRoom(): void {
    this.setAppointmentInfo();
    this.router.navigate(['../video']);
  }

  private subscribeToSelectedRequest(): void {
    this.requestDetailsService.getSelectedRequest()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (request) => {
          this.isItemSelected = request?.id === this.appointmentInfo.id;
        }
      );
  }

  private subscribeToSelectedPatient(): void {
    this.patientService.getPatientInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (patient) => {
          this.isItemSelected = patient?.appointmentId === this.appointmentInfo.id;
        }
      );
  }

  private setUser(): void {
    if (this.role === RoleEnum.Doctor) {
      this.currentPatientInfo = this.appointmentInfo.familyMemberInfo || this.appointmentInfo.patientInfo;
    } else {
      this.currentPatientInfo = this.appointmentInfo.familyMemberInfo || this.appointmentInfo.patientInfo;
      this.currentDoctorInfo = this.appointmentInfo.doctorInfo;
    }
  }

  private setAppointmentInfo(): void {
    this.patientId.emit(this.currentPatientInfo?.id);
    this.patientService.setPatientInfo(new Patient(this.currentPatientInfo, this.appointmentInfo) as PatientInterface);
    this.patientService.setPatientInfoVideo(new Patient(this.currentPatientInfo, this.appointmentInfo) as PatientInterface);
    this.selectedAppointmentService.setSelectedAppointment(this.appointmentInfo);
    this.videoService.appointmentInfoSubject$.next(this.appointmentInfo);
    this.joinToWaitingRoomStatus.emit(this.appointmentStatus);
  }
}
