import { AppointmentStatusEnum } from '../enums';

export const appointmentStatusListConstant = [
  {
    'code': AppointmentStatusEnum.DoctorDecisionPending,
    'name': 'appointmentStatus.doctorDecisionPendingLabel',
  },
  {
    'code': AppointmentStatusEnum.ResponsePending,
    'name': 'appointmentStatus.responsePendingLabel',
  },
  {
    'code': AppointmentStatusEnum.PatientDecisionPending,
    'name': 'appointmentStatus.patientDecisionPendingLabel',
  },
  {
    'code': AppointmentStatusEnum.Scheduled,
    'name': 'appointmentStatus.scheduledLabel',
  },
  {
    'code': AppointmentStatusEnum.Ongoing,
    'name': 'appointmentStatus.ongoingLabel',
  },
  {
    'code': AppointmentStatusEnum.Declined,
    'name': 'appointmentStatus.declinedLabel',
  },
  {
    'code': AppointmentStatusEnum.VisitFinished,
    'name': 'appointmentStatus.visitFinishedLabel',
  },
  {
    'code': AppointmentStatusEnum.FollowUpDecisionPending,
    'name': 'appointmentStatus.followUpDecisionPendingLabel',
  },
  {
    'code': AppointmentStatusEnum.FollowUpScheduled,
    'name': 'appointmentStatus.followUpScheduledLabel',
  },
  {
    'code': AppointmentStatusEnum.FollowUpDeclined,
    'name': 'appointmentStatus.followUpDeclinedLabel',
  },
  {
    'code': AppointmentStatusEnum.FollowUpFinished,
    'name': 'appointmentStatus.followUpFinishedLabel',
  },
  {
    'code': AppointmentStatusEnum.Canceled,
    'name': 'appointmentStatus.canceledLabel',
  },
  {
    'code': AppointmentStatusEnum.Expired,
    'name': 'appointmentStatus.expiredLabel',
  },
];
