<div class="modal-wrapper">
  <div class="modal-header basic-icon-button">
    <h1 class="page-title">{{'messages.membersLabel' | translate}}</h1>
  </div>


  <div class="colleagues-list">
    <div *ngFor="let participant of participants" class="colleague-item">
      <p class="name">{{participant.firstName + ' ' + participant.lastName}}</p>
    </div>
  </div>
</div>
