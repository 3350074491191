<section class="error-section">
  <div class="error-wrapper">
    <h1 class="error-title">404</h1>

    <div class="error-info">
      <p class="error-subtitle">{{'notFound.notFoundLabel' | translate}}</p>

      <p class="error-text">{{'notFound.notFoundInfoLabel' | translate}}</p>
    </div>

    <button (click)="backButtonClick()" class="big-button button-primary wide-button"
            mat-button>{{'general.goBackLabel' | translate}}</button>
  </div>
</section>
