export enum UsageFormEnum {
  Caps = 0,
  Tabs = 1,
  Vial = 2,
  Amp = 3,
  Drops = 4,
  Ml = 5,
  Mg = 6,
  Spray = 7
}
