export * from './filter-patients.interface';
export * from './filter-visits.interface';
export * from './medical-history.interface';
export * from './patientInterface';
export * from './prescription.interface';
export * from './previous-visit-info.interface';
export * from './recommendation.interface';
export * from './patient-ehr-general-info.interface';
export * from './patient-general-info.interface';
export * from './question-answer.interface';
